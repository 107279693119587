var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Table',{attrs:{"headers":_vm.cols,"items":_vm.documents,"custom-sort":_vm.prevent_sort,"not_resizeble":"","no-data-text":"Документы отсутствуют"},scopedSlots:_vm._u([{key:"item.document_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.date)+" ")])]}},{key:"item.document_name",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[(item.status)?_c('div',[_c('ChipStatus',{attrs:{"status":_vm.get_dispatch_status(item.status)}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1):_vm._e()])]}},{key:"item.document_actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"#000","href":item.path,"target":"_blank","disabled":!_vm.is_pdf(item)}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-printer-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Печать")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('DownloadBtn',_vm._g(_vm._b({attrs:{"src":item.path}},'DownloadBtn',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Загрузить")])])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }